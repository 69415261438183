import React from 'react'
import { Box } from '@mui/material'
import { useSearchResults } from '@lib/admin/queries'
import { LoadingIndicator } from '@components/admin/loadingIndicator'
import { NoResults } from '@components/admin/noResults'
import { SearchStats } from '@components/admin/searchStats'
import { SearchTable } from '@components/admin/searchTable'

export const SearchScreen: React.FC = () => {
  const { dealNodeGroups, usedQuery, isLoading } = useSearchResults()

  const innerContent = isLoading ? (
    <LoadingIndicator />
  ) : !dealNodeGroups.length ? (
    <NoResults />
  ) : (
    <SearchTable dealNodeGroups={dealNodeGroups} />
  )

  return (
    <>
      <Box px={3} pt={6} pb={3} display='flex' flexDirection='row'>
        <SearchStats usedQuery={usedQuery} dealNodeGroups={dealNodeGroups} />
      </Box>
      <Box padding={3}>{innerContent}</Box>
    </>
  )
}
