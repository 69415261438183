import React from 'react'
import { Box, Typography } from '@mui/material'

export const NoResults: React.FC = () => {
  return (
    <Box textAlign='center' pt={12} pb={12}>
      <Typography
        sx={({ typography }) => ({
          fontWeight: typography.fontWeightBold,
        })}
        variant='h5'
      >
        No Results
      </Typography>
    </Box>
  )
}
