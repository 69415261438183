import React, { useState } from 'react'
import { Dialog, IconButton, Menu, MenuItem, MenuListProps } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { IDeal } from '@lib/admin/types'
import { SendWelcomeEmailContent } from '@components/admin/sendWelcomeEmail'
import { RenewalQuoteContent } from '@components/admin/renewalQuote'
import { ConfirmDuplicateContent } from '@components/admin/confirmDuplicate'
import { BankDetailsDialog } from '@components/admin/bankDetailsDialog'

export interface IMoreProps {
  deal: IDeal
}

export const More: React.FC<IMoreProps> = ({ deal }: IMoreProps) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null)
  const [dialogContent, setDialogContent] = useState<string>('')

  const handleClose = () => {
    setDialogContent('')
  }
  const dialogContentMap: { [key: string]: JSX.Element } = {
    'welcome-email': <SendWelcomeEmailContent deal={deal} onClose={handleClose} />,
    'new-quote': <RenewalQuoteContent oldDeal={deal} onClose={handleClose} />,
    'confirm-duplicate': <ConfirmDuplicateContent deal={deal} onClose={handleClose} />,
    'virtual-bank-details': <BankDetailsDialog deal={deal} onClose={handleClose} />,
  }
  const allowedWelcome = Boolean(
    deal.status === 'paid' ||
    deal.status === 'async' ||
    (deal.status === 'invoiced' && deal.paymentTerm)
  )

  return (
    <>
      <IconButton
        aria-label='more'
        onClick={(e) => {
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVert color='action' />
      </IconButton>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        MenuListProps={{ component: 'div' } as MenuListProps}
        onClick={() => {
          setMenuAnchor(null)
        }}
      >
        <MenuItem component='a' target='_blank' href={deal.pdfUrl} rel='noreferrer'>
          Download PDF
        </MenuItem>
        {allowedWelcome && (
          <MenuItem
            component='a'
            onClick={() => {
              setDialogContent('welcome-email')
            }}
          >
            Send welcome email to...
          </MenuItem>
        )}
        {deal.url && (
          <MenuItem component='a' target='_blank' href={deal.url} rel='noreferrer'>
            View / Edit invoice
          </MenuItem>
        )}
        {deal.url && (
          <MenuItem component='a' target='_blank' href={`${deal.url}?super`} rel='noreferrer'>
            View / Edit invoice (as superuser)
          </MenuItem>
        )}
        {deal.status === 'paid' && (
          <MenuItem
            component='a'
            onClick={() => {
              setDialogContent('new-quote')
            }}
          >
            Create renewal quote
          </MenuItem>
        )}
        {deal && (
          <MenuItem
            component='a'
            onClick={() => {
              setDialogContent('virtual-bank-details')
            }}
          >
            Get virtual bank details
          </MenuItem>
        )}
        {deal.crmUrl && (
          <MenuItem component='a' target='_blank' href={deal.crmUrl} rel='noreferrer'>
            View in CRM
          </MenuItem>
        )}
        {(deal.status === 'new' || deal.status === 'quoted' || deal.status === 'invoiced') && (
          <MenuItem
            component='a'
            onClick={() => {
              setDialogContent('confirm-duplicate')
            }}
          >
            Mark as duplicate
          </MenuItem>
        )}
      </Menu>
      <Dialog open={dialogContent !== ''} onClose={handleClose}>
        {dialogContentMap[dialogContent] || <></>}
      </Dialog>
    </>
  )
}
