import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

export interface ISectionProps {
  children: ReactNode
}

export const Section: React.FC<ISectionProps> = (props: ISectionProps) => (
  <Box mb={3}>{props.children}</Box>
)
