import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import {
  clearQueryCaches,
  refetchQueries,
  useCoupons,
  usePaymentTerms,
  usePricingModelIds,
  useSearchQuery,
  useSearchResults,
} from '@lib/admin/queries'
import { DiscountInput } from '@components/admin/discountInput'
import { createNewQuote } from '@lib/admin/requests'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(3),
    },
    paper: {
      minWidth: '370px',
    },
  })
)

interface INewQuoteContentProps {
  onClose: () => void
}

const getInitialContactName = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('contact-name') ?? ''
}

const NewQuoteContent: React.FC<INewQuoteContentProps> = ({ onClose }: INewQuoteContentProps) => {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { email: searchResultsEmail } = useSearchResults()
  const [searchQuery, setSearchQuery] = useSearchQuery()

  const { pricingModelIds } = usePricingModelIds()
  const { paymentTerms } = usePaymentTerms()
  const { coupons } = useCoupons()

  // form values
  const [contactName, setContactName] = useState<string>(getInitialContactName())
  const [contactEmail, setContactEmail] = useState<string>(searchResultsEmail)
  const [pricingModelId, setPricingModelId] = useState<string>(
    pricingModelIds.length ? pricingModelIds[0] : ''
  )
  const [paymentTermId, setPaymentTermId] = useState<string>(
    paymentTerms.length ? paymentTerms[0].id : ''
  )
  const [couponId, setCouponId] = useState<string>('')

  const saveQuote = async () => {
    const loadingSnack = enqueueSnackbar('Saving quote...', { variant: 'info' })
    try {
      const res = await createNewQuote({
        contactName,
        contactEmail,
        pricingModelId,
        paymentTermId,
        couponId,
      })
      closeSnackbar(loadingSnack)
      if (res.error) {
        enqueueSnackbar(res.error, { variant: 'error' })
      } else {
        enqueueSnackbar('Quote saved!', { variant: 'success' })
        onClose()
        if (searchQuery === contactEmail) {
          refetchQueries()
        } else {
          clearQueryCaches()
          setSearchQuery(contactEmail)
        }
        return res
      }
    } catch (error) {
      closeSnackbar(loadingSnack)
      enqueueSnackbar('Network error while saving quote', {
        variant: 'error',
      })
    }
  }
  const saveQuoteProceed = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const res = await saveQuote()
    if (res) {
      window.open(res.url)
    }
  }

  return (
    <form onSubmit={saveQuoteProceed}>
      <Box className={classes.contentBox}>
        <Typography variant='h6'>New Quote</Typography>
        <FormControl margin='normal'>
          <TextField
            label='Full Name'
            value={contactName}
            autoFocus
            spellCheck={false}
            onChange={(e) => {
              setContactName(e.target.value)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <TextField
            label='Email'
            spellCheck={false}
            value={contactEmail}
            onChange={(e) => {
              setContactEmail(e.target.value)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id='license-type-label'>License Type</InputLabel>
          <Select
            labelId='license-type-label'
            value={pricingModelId}
            onChange={(e) => {
              setPricingModelId(e.target.value as string)
            }}
          >
            {pricingModelIds.map((id: string) => (
              <MenuItem key={id} value={id}>
                {id.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id='payment-term-label'>Payment Term</InputLabel>
          <Select
            labelId='payment-term-label'
            value={paymentTermId}
            onChange={(e) => {
              setPaymentTermId(e.target.value as string)
            }}
          >
            {paymentTerms.map(({ id, description }) => (
              <MenuItem key={id} value={id}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <DiscountInput
            coupons={coupons}
            value={couponId}
            onChange={(e) => {
              setCouponId(e.target.value as string)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <Button variant='contained' color='primary' type='submit'>
            Proceed
          </Button>
        </FormControl>
      </Box>
    </form>
  )
}

/*
the initiating button AND the popover
*/
export const NewQuote: React.FC = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
  const classes = useStyles()

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchor(e.currentTarget)
        }}
        color='inherit'
      >
        New Quote
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ className: classes.paper }}
      >
        <NewQuoteContent onClose={handleClose} />
      </Popover>
    </>
  )
}
