import React, { useState } from 'react'
import { Box, Button, Popover, FormControl, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { DatePicker } from '@mui/x-date-pickers'
import { DateType } from '@date-io/type'
import { createNewKey } from '@lib/admin/requests'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    button: {
      marginLeft: spacing(3),
    },
    paper: {
      minWidth: '370px',
    },
  })
)

interface INewKeyContentProps {
  onClose: () => void
}

const NewKeyContent: React.FC<INewKeyContentProps> = ({ onClose }: INewKeyContentProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [expirationDate, setExpirationDate] = useState<DateType>(new Date())

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const loadingSnack = enqueueSnackbar('Creating key...', { variant: 'info' })
    try {
      const res = await createNewKey(expirationDate)
      closeSnackbar(loadingSnack)
      if (res.error) {
        enqueueSnackbar(res.error, { variant: 'error' })
      } else {
        enqueueSnackbar(`New Key: ${res.licenseKey}`, {
          variant: 'success',
          persist: true,
        })
        onClose()
      }
    } catch (error) {
      closeSnackbar(loadingSnack)
      enqueueSnackbar('Network error while saving key', { variant: 'error' })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display='flex' flexDirection='column' p={3}>
        <Typography variant='h6'>New Key</Typography>
        <FormControl margin='normal'>
          <DatePicker
            disablePast
            label='Expiration Date'
            value={expirationDate}
            onChange={(date) => {
              if (date !== null) {
                setExpirationDate(date)
              }
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <Button variant='contained' color='primary' type='submit'>
            Create
          </Button>
        </FormControl>
      </Box>
    </form>
  )
}

/*
the initiating button AND the popover
*/
export const NewKey: React.FC = () => {
  const classes = useStyles()
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <Button
        className={classes.button}
        onClick={(e) => {
          setAnchor(e.currentTarget)
        }}
        color='inherit'
      >
        New Key
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ className: classes.paper }}
      >
        <NewKeyContent onClose={handleClose} />
      </Popover>
    </>
  )
}
