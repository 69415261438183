import React, { useEffect, useState } from 'react'
import { IDeal } from '@lib/admin/types'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { getBankDetails, BankDetailsResponse } from '@lib/admin/requests'

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      textAlign: 'center',
    },
  })
)

type BankDetailsDialogProps = {
  deal: IDeal
  onClose: () => void
}

export const BankDetailsDialog: React.FC<BankDetailsDialogProps> = ({ deal, onClose }) => {
  const [details, setDetails] = useState<BankDetailsResponse | null>(null)
  const classes = useStyles()

  useEffect(() => {
    getBankDetails(deal.id).then((response) => {
      setDetails(response)
    })
  }, [])

  return (
    <>
      <DialogTitle>Virtual Bank Information</DialogTitle>
      <DialogContent>
        <DialogContentText className={details ? '' : classes.center}>
          {details ? (
            <>
              Bank Name:&nbsp;<b>{details.bankName}</b>
              <br />
              Account Number:&nbsp;<b>{details.accountNumber}</b>
              <br />
              Routing Number:&nbsp;<b>{details.routingNumber}</b>
              <br />
              Swift Code:&nbsp;<b>{details.swiftCode}</b>
            </>
          ) : (
            <CircularProgress />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </>
  )
}
