import React from 'react'
import { Select, InputLabel, MenuItem } from '@mui/material'
import { ICoupon } from '@lib/admin/types'

export interface IDiscountInputProps {
  coupons: ICoupon[]
  value: string
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => void
}

export const DiscountInput: React.FC<IDiscountInputProps> = ({
  coupons,
  value,
  onChange,
}: IDiscountInputProps) => {
  return (
    <>
      <InputLabel shrink id='discount-label'>
        Discount
      </InputLabel>
      <Select labelId='discount-label' displayEmpty value={value} onChange={onChange}>
        <MenuItem key='discount-none' value=''>
          None
        </MenuItem>
        {coupons.map((coupon: ICoupon) => (
          <MenuItem key={`discount-${coupon.id}`} value={coupon.id}>
            {coupon.description} ({coupon.percent ? `${coupon.percent}%` : `$${coupon.flatAmount}`})
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
