import React, { MouseEventHandler } from 'react'
import { Button, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'

export interface IConfirmContentProps {
  message: string
  onConfirm: MouseEventHandler
  onCancel: MouseEventHandler
}

export const ConfirmContent: React.FC<IConfirmContentProps> = (props: IConfirmContentProps) => {
  return (
    <>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color='primary'>
          No
        </Button>
        <Button onClick={props.onConfirm} color='primary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </>
  )
}
