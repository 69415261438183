import React, { useMemo } from 'react'
import clsx from 'clsx'
import WarningIcon from '@mui/icons-material/Warning'
import { IDeal, ITransaction } from '@lib/admin/types'
import { currencyFormatter } from '@lib/admin/misc'
import { DoubleText } from '@components/admin/doubleText'
import { Box, IconButton, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(({ typography }: Theme) =>
  createStyles({
    bold: {
      fontWeight: typography.fontWeightBold,
    },
    paid: {
      color: '#66bb6a', // green (palette.success.main in MUI5)
    },
    new: {
      color: '#ffa726', // orange (palette.warning.main in MUI5)
    },
    quoted: {
      color: '#ce93d8', // magenta (palette.secondary.main in MUI5)
    },
    async: {
      color: '#ffa726', // orange (palette.warning.main in MUI5)
    },
    invoiced: {
      color: '#29b6f6', // blue (palette.info.main in MUI5)
    },
    unsupported: {
      color: '#ffa726', // orange (palette.warning.main in MUI5)
    },
    cancelled: {
      color: '#f44336', // red (palette.error.main in MUI5)
    },
  })
)

interface IStatusProps {
  deal: IDeal
  transactions: ITransaction[]
}

export const Status: React.FC<IStatusProps> = ({ deal, transactions }: IStatusProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const styles = useStyles()

  const gross = useMemo(
    () => computeTransactionsTotal(transactions) || deal.amount,
    [transactions, deal]
  )
  const isUnsupported = deal.status === 'paid' && !deal.isSupported
  const statusText = isUnsupported ? 'unsupported' : deal.status

  return (
    <Box display='flex'>
      <Box>
        <DoubleText
          top={
            <p className={clsx(styles.bold, styles[isUnsupported ? 'unsupported' : deal.status])}>
              {currencyFormatter.format(gross)}
            </p>
          }
          bottom={
            deal.url ? (
              <a target='_blank' href={deal.url} rel='noreferrer'>
                {statusText + (deal.isPaidViaAuto ? ' (auto)' : '')}
              </a>
            ) : (
              statusText
            )
          }
        />
      </Box>
      <Box ml={2}>
        {!deal.crmUrl && (
          <IconButton
            title='Not synced to CRM'
            disableRipple
            onClick={() => {
              enqueueSnackbar('Not synced to CRM', { variant: 'info' })
            }}
          >
            <WarningIcon color='action' />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

function computeTransactionsTotal(transactions: ITransaction[]): number {
  return transactions.reduce((acc, { type, gross }) => {
    const val = type === 'async' ? 0 : gross
    return acc + val
  }, 0)
}
