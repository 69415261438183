import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { useReportRange } from '@lib/admin/queries'

export const MonthPicker: React.FC = () => {
  const [[start], setReportRange] = useReportRange()
  return (
    <DatePicker
      value={new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate())}
      disableFuture
      openTo='year'
      views={['year', 'month']}
      onMonthChange={(date) => {
        setReportRange(date.getFullYear(), date.getMonth())
      }}
    />
  )
}
