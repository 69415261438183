import React, { useState } from 'react'
import { Box, Typography, Accordion, AccordionDetails, Theme, IconButton } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ITransaction, IDeal } from '@lib/admin/types'
import { More } from '@components/admin/dealTable/more'
import { Status } from '@components/admin/dealTable/status'
import { Payments } from '@components/admin/dealTable/payments'
import { License } from '@components/admin/dealTable/license'
import { Contact } from '@components/admin/dealTable/contact'
import { Company } from '@components/admin/dealTable/company'
import { Client } from '@components/admin/dealTable/client'
import { DateCell } from '@components/admin/dealTable/dateCell'

const useStyles = makeStyles(({ typography, spacing }: Theme) =>
  createStyles({
    commonCell: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 0,
      minWidth: 0,
      margin: spacing(1),
    },
    moreCell: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 48,
    },
    dateCell: {
      textAlign: 'right',
    },
    chevronCell: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 48,
    },
    accordion: {
      padding: '12px 0',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
    },
  })
)

export const HeaderRow: React.FC = () => {
  const classes = useStyles()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
      mb={1.5}
    >
      <Box className={clsx(classes.commonCell, classes.moreCell)} />
      <Box className={classes.commonCell}>
        <Typography className={classes.bold}>Status</Typography>
      </Box>
      <Box className={classes.commonCell}>
        <Typography className={classes.bold}>License</Typography>
      </Box>
      <Box className={classes.commonCell}>
        <Typography className={classes.bold}>Contact</Typography>
      </Box>
      <Box className={classes.commonCell}>
        <Typography className={classes.bold}>Company</Typography>
      </Box>
      <Box className={classes.commonCell}>
        <Typography className={classes.bold}>Client</Typography>
      </Box>
      <Box className={clsx(classes.commonCell, classes.dateCell)}>
        <Typography className={classes.bold}>Date</Typography>
      </Box>
      <Box className={clsx(classes.commonCell, classes.chevronCell)} />
    </Box>
  )
}

interface IDealRowProps {
  deal: IDeal
  transactions: ITransaction[]
  isoDate: string // an ISO date string to display on the row
}

export const DealRow: React.FC<IDealRowProps> = ({
  deal,
  transactions,
  isoDate,
}: IDealRowProps) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded && transactions.length > 0}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box className={clsx(classes.commonCell, classes.moreCell)}>
          <More deal={deal} />
        </Box>
        <Box className={classes.commonCell}>
          <Status deal={deal} transactions={transactions} />
        </Box>
        <Box className={classes.commonCell}>
          <License deal={deal} />
        </Box>
        <Box className={classes.commonCell}>
          <Contact deal={deal} />
        </Box>
        <Box className={classes.commonCell}>
          <Company deal={deal} />
        </Box>
        <Box className={classes.commonCell}>
          <Client deal={deal} />
        </Box>
        <Box className={clsx(classes.commonCell, classes.dateCell)}>
          <DateCell
            deal={deal}
            transactions={transactions}
            isoDate={isoDate}
            expand={() => {
              setExpanded((val) => !val)
            }}
          />
        </Box>
        <Box className={clsx(classes.commonCell, classes.chevronCell)}>
          <IconButton
            aria-label='more'
            disabled={transactions.length <= 0}
            style={{
              visibility: transactions.length > 0 ? 'visible' : 'hidden',
            }}
            onClick={() => {
              setExpanded((val) => !val)
            }}
          >
            {expanded ? <ExpandLess color='action' /> : <ExpandMore color='action' />}
          </IconButton>
        </Box>
      </Box>
      <AccordionDetails>
        <Payments transactions={transactions} />
      </AccordionDetails>
    </Accordion>
  )
}
