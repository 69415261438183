import React from 'react'
import { Box } from '@mui/material'
import { useReportResults } from '@lib/admin/queries'
import { LoadingIndicator } from '@components/admin/loadingIndicator'
import { NoResults } from '@components/admin/noResults'
import { ReportStats } from '@components/admin/reportStats'
import { ReportTable } from '@components/admin/reportTable'

export const ReportScreen: React.FC = () => {
  const { transactionNodes, isLoading } = useReportResults()

  const innerContent = isLoading ? (
    <LoadingIndicator />
  ) : !transactionNodes.length ? (
    <NoResults />
  ) : (
    <ReportTable transactionNodes={transactionNodes} />
  )

  return (
    <>
      <Box px={3} pt={6} pb={3} display='flex' flexDirection='row'>
        <ReportStats transactionNodes={transactionNodes} />
      </Box>
      <Box padding={3}>{innerContent}</Box>
    </>
  )
}
