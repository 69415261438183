import { useCallback, useMemo } from 'react'
import { QueryClient, useQuery, UseQueryResult } from 'react-query'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import {
  ICoupon,
  IRootResults,
  IPaymentTerm,
  IReportResults,
  ISearchResults,
} from '@lib/admin/types'
import { createUtcDate, formatIsoDay, getCurrentMonthRange, parseIsoDay } from './date'
import { fetchRootResults } from './requests'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

export function refetchQueries(): void {
  queryClient.refetchQueries(undefined, { active: true })
}

export function clearQueryCaches(): void {
  queryClient.clear()
}

export function buildSearchParamStr(searchQuery: string): string {
  const params = new URLSearchParams(location.search)
  // Special case of blank query should not have any 'q='
  if (searchQuery !== '') {
    params.set('q', searchQuery)
  } else {
    params.delete('q')
  }
  return '?' + params.toString()
}

export const useSearchQuery = (): [string, (q: string) => void] => {
  const location = useLocation()

  const searchQuery = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('q') ?? ''
  }, [location])

  const setSearchQuery = useCallback(
    (newSearchQuery: string) => {
      // Check searchQuery is different from previous
      if (newSearchQuery !== searchQuery) {
        navigate('/admin' + buildSearchParamStr(newSearchQuery))
      }
    },
    [searchQuery]
  )

  return [searchQuery, setSearchQuery]
}

export const useReportRange = (): [[Date, Date], (year: number, month: number) => void] => {
  const location = useLocation()

  const [start, end] = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const paramsStart = parseIsoDay(params.get('start') || '')
    const paramsEnd = parseIsoDay(params.get('end') || '')

    return paramsStart && paramsEnd ? [paramsStart, paramsEnd] : getCurrentMonthRange()
  }, [location])

  const setReportRange = useCallback((year: number, month: number) => {
    const params = new URLSearchParams(location.search)
    params.set('start', formatIsoDay(createUtcDate(year, month)))
    params.set('end', formatIsoDay(createUtcDate(year, month + 1)))
    navigate(`/admin?${params.toString()}`)
  }, [])

  return [[start, end], setReportRange]
}

const useRootResults = (): UseQueryResult<IRootResults, Error> => {
  const [searchQuery] = useSearchQuery()
  const [[reportStart, reportEnd]] = useReportRange()

  return useQuery<IRootResults, Error>(
    [searchQuery, reportStart, reportEnd], // serializes date string correctly?
    () => {
      return fetchRootResults(searchQuery, reportStart, reportEnd)
    }
  )
}

export const useSearchResults = (): ISearchResults & { isLoading: boolean } => {
  const { data, isLoading } = useRootResults()
  const searchResults = data?.searchResults
  return {
    isLoading,
    ...(searchResults || {
      givenQuery: '',
      usedQuery: '',
      url: '',
      email: '',
      dealNodeGroups: [],
    }),
  }
}

export const useReportResults = (): IReportResults & { isLoading: boolean } => {
  const { data, isLoading } = useRootResults()
  const reportResults = data?.reportResults
  return {
    isLoading,
    ...(reportResults || {
      startDate: '',
      endDate: '',
      transactionNodes: [],
    }),
  }
}

export const usePricingModelIds = (): {
  pricingModelIds: string[]
  isLoading: boolean
} => {
  const { data, isLoading } = useRootResults()
  return {
    pricingModelIds: data ? data.pricingModelIds : [],
    isLoading,
  }
}

export const usePaymentTerms = (): {
  paymentTerms: IPaymentTerm[]
  isLoading: boolean
} => {
  const { data, isLoading } = useRootResults()
  return {
    paymentTerms: data ? data.paymentTerms : [],
    isLoading,
  }
}

export const useCoupons = (): {
  coupons: ICoupon[]
  isLoading: boolean
} => {
  const { data, isLoading } = useRootResults()
  return {
    coupons: data ? data.coupons : [],
    isLoading,
  }
}
