import React from 'react'
import { IconButton } from '@mui/material'
import { Brightness4, BrightnessHigh } from '@mui/icons-material'
import { usePaletteAtom } from '@lib/admin/atoms'

export const PaletteToggle: React.FC = () => {
  const [palette, setPalette] = usePaletteAtom()
  return (
    <IconButton
      onClick={() => {
        setPalette(palette === 'dark' ? 'light' : 'dark')
      }}
    >
      {palette === 'dark' ? (
        <BrightnessHigh
          sx={({ palette }) => ({
            color: palette.common.white,
          })}
        />
      ) : (
        <Brightness4
          sx={({ palette }) => ({
            color: palette.common.white,
          })}
        />
      )}
    </IconButton>
  )
}
