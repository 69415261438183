import React, { memo } from 'react'
import { IDealNode } from '@lib/admin/types'
import { DealRow, HeaderRow } from '@components/admin/dealTable/dealRow'
import { Section } from '@components/admin/dealTable/section'

export interface ISearchTableProps {
  dealNodeGroups: IDealNode[][]
}

function _SearchTable(props: ISearchTableProps) {
  return (
    <>
      <HeaderRow />
      {props.dealNodeGroups.map((dealNodeGroup) => (
        <Section key={dealNodeGroup[0].id}>
          {dealNodeGroup.map((dealNode) => (
            <DealRow
              key={dealNode.id}
              deal={dealNode}
              transactions={dealNode.transactions}
              isoDate={dealNode.createdDate}
            />
          ))}
        </Section>
      ))}
    </>
  )
}

export const SearchTable = memo(_SearchTable)
