import React, { memo } from 'react'
import { DealRow, HeaderRow } from '@components/admin/dealTable/dealRow'
import { Section } from '@components/admin/dealTable/section'
import { ITransactionNode } from '@lib/admin/types'

export interface IReportTableProps {
  transactionNodes: ITransactionNode[]
}

function _ReportTable(props: IReportTableProps) {
  return (
    <>
      <HeaderRow />
      <Section>
        {props.transactionNodes.map((transactionNode) => (
          <DealRow
            key={transactionNode.id}
            deal={transactionNode.deal}
            transactions={[transactionNode]}
            isoDate={transactionNode.date}
          />
        ))}
      </Section>
    </>
  )
}

export const ReportTable = memo(_ReportTable)
