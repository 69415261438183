import React from 'react'
import { IDeal } from '@lib/admin/types'
import { cleanUrl } from '@lib/admin/misc'
import { DoubleText } from '@components/admin/doubleText'

interface ICompanyProps {
  deal: IDeal
}

export const Company: React.FC<ICompanyProps> = ({ deal }: ICompanyProps) => {
  return (
    <DoubleText
      top={deal.companyName}
      bottom={
        <a target='_blank' href={deal.companyUrl} rel='noreferrer'>
          {cleanUrl(deal.companyUrl)}
        </a>
      }
    />
  )
}
