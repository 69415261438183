import axios, { AxiosResponse, AxiosError } from 'axios'
import { IRootResults } from '@lib/admin/types'
import { formatIsoDay } from './date'

export const fetchRootResults = async (
  searchQuery: string,
  reportStart: Date,
  reportEnd: Date
): Promise<IRootResults> => {
  return axios
    .get('/api/admin/page-data', {
      params: searchQuery
        ? { searchQuery }
        : {
            reportStart: formatIsoDay(reportStart),
            reportEnd: formatIsoDay(reportEnd),
          },
    })
    .then((response) => response.data)
    .catch(handleError)
}

export interface NewKeyResponse {
  error?: string
  licenseKey: string
}

export const createNewKey = async (expirationDate: Date): Promise<NewKeyResponse> => {
  return axios
    .post<{ expirationDate: Date }, AxiosResponse<NewKeyResponse>>('/api/commercial-license/key', {
      expirationDate, // serializes to UTC string
    })
    .then((response) => response.data)
    .catch(handleError)
}

export interface NewQuoteRequest {
  pricingModelId: string
  contactName: string
  contactEmail: string
  couponId: string
  paymentTermId: string
}

export interface RenewalQuoteRequest {
  oldDealId: string
  contactName: string
  contactEmail: string
  couponId: string
  paymentTermId: string
  disablePenalty: boolean
}

export interface QuoteResponse {
  error?: string
  id: string
  url: string
  pdfUrl: string
}

export const createNewQuote = async (req: NewQuoteRequest): Promise<QuoteResponse> => {
  return axios
    .post<NewQuoteRequest, AxiosResponse<QuoteResponse>>('/api/deal/quote', req)
    .then((response) => response.data)
    .catch(handleError)
}

export const createRenewalQuote = async (req: RenewalQuoteRequest): Promise<QuoteResponse> => {
  return axios
    .post<RenewalQuoteRequest, AxiosResponse<QuoteResponse>>('/api/deal/quote', req)
    .then((response) => response.data)
    .catch(handleError)
}

export interface SendWelcomeRequest {
  dealId: string
  recipientName: string
  recipientEmail: string
  contactUpdate: boolean
  billingUpdate: boolean
}

export interface SendWelcomeResponse {
  error?: string
}

export const sendWelcomeEmail = async (req: SendWelcomeRequest): Promise<SendWelcomeResponse> => {
  return axios
    .post<SendWelcomeRequest, AxiosResponse<SendWelcomeResponse>>('/api/deal/welcome', req)
    .then((response) => response.data)
    .catch(handleError)
}

export type BankDetailsResponse = {
  accountNumber: number
  routingNumber: number
  swiftCode: number
  bankName: string
}

export const getBankDetails = async (dealId: string): Promise<BankDetailsResponse> => {
  return axios
    .post('/api/deal/bank-details', {
      dealId,
    })
    .then((response) => response.data)
    .catch(handleError)
}

export interface DealDuplicateResponse {
  success: boolean
  error: string
}

export const markDuplicateDeal = async (dealId: string): Promise<DealDuplicateResponse> => {
  return axios
    .post(`/api/deal/cancel`, {
      dealId,
      reason: 'duplicate',
    })
    .then((response) => response.data)
    .catch(handleError)
}

// Handles OAuth redirect
function handleError(error: AxiosError): any {
  const { response } = error
  if (response) {
    const redirectUrl: string = (response.data as any).redirectUrl
    if (redirectUrl) {
      window.location.href = redirectUrl
      return
    }
  }
  throw error
}
