import React, { useEffect, useState } from 'react'
import { Box, InputBase, alpha } from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import Search from '@mui/icons-material/Search'
import { useSearchQuery } from '@lib/admin/queries'

export const SearchBar: React.FC = () => {
  const [searchQuery, setSearchQuery] = useSearchQuery()
  const [localSearch, setLocalSearch] = useState(searchQuery)

  useEffect(() => {
    setLocalSearch(searchQuery)
  }, [searchQuery])

  return (
    <Box
      sx={({ palette, shape }) => ({
        position: 'relative',
        borderRadius: shape.borderRadius,
        backgroundColor: alpha(palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(palette.common.white, 0.25),
        },
      })}
    >
      <InputBase
        placeholder='Search...'
        autoFocus
        startAdornment={
          <Search
            sx={({ palette, spacing }) => ({
              padding: spacing(0, 1),
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'auto',
              color: palette.common.white,
            })}
            fontSize='large'
          />
        }
        endAdornment={
          searchQuery !== '' && (
            <Clear
              onClick={() => {
                setSearchQuery('')
              }}
              sx={({ palette, spacing }) => ({
                padding: spacing(0, 1),
                cursor: 'pointer',
                color: palette.common.white,
              })}
              fontSize='large'
            />
          )
        }
        sx={({ palette, spacing }) => ({
          '& input': {
            padding: spacing(1, 1, 1, 4.5),
            color: palette.common.white,
            width: '300px',
          },
        })}
        inputProps={{ 'aria-label': 'search' }}
        value={localSearch}
        onChange={(e) => {
          setLocalSearch(e.target.value)
        }}
        onBlur={() => {
          setSearchQuery(localSearch)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setSearchQuery(localSearch)
          }
        }}
      />
    </Box>
  )
}
