import React, { useMemo } from 'react'
import { ThemeProvider, CssBaseline, Collapse, createTheme, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { QueryClientProvider } from 'react-query'
import { queryClient } from '@lib/admin/queries'
import { usePaletteAtom } from '@lib/admin/atoms'

export const AppWrapper: React.FC = ({ children }) => {
  const [palette] = usePaletteAtom()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: palette,
          background: palette === 'light' ? { default: '#fafafa' } : {},
        },
        components: {
          MuiDialog: {
            styleOverrides: {
              paper: {
                minWidth: '370px',
              },
            },
          },
        },
      }),
    [palette]
  )

  const dismissButton = (key: string) => {
    const snackbar = useSnackbar()
    return (
      <Button
        color='inherit'
        onClick={() => {
          snackbar.closeSnackbar(key)
        }}
      >
        Dismiss
      </Button>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={5}
            TransitionComponent={Collapse}
            preventDuplicate
            action={dismissButton}
          >
            {children}
          </SnackbarProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
