import React from 'react'
import { IDeal } from '@lib/admin/types'
import { cleanUrl } from '@lib/admin/misc'
import { DoubleText } from '@components/admin/doubleText'

interface IClientProps {
  deal: IDeal
}

export const Client: React.FC<IClientProps> = ({ deal }: IClientProps) => {
  return deal.clientName ? (
    <DoubleText
      top={deal.clientName}
      bottom={
        <a target='_blank' href={deal.clientUrl} rel='noreferrer'>
          {cleanUrl(deal.clientUrl)}
        </a>
      }
    />
  ) : (
    <></>
  )
}
