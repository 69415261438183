import React, { memo } from 'react'
import { Box } from '@mui/material'
import { currencyFormatter } from '@lib/admin/misc'
import { ITransactionNode } from '@lib/admin/types'
import { computeReportSums } from '@lib/admin/reportSums'
import { ValueDisplay } from '@components/admin/valueDisplay'
import { MonthPicker } from '@components/admin/monthPicker'

export interface IReportStatsProps {
  transactionNodes: ITransactionNode[]
}

function _ReportStats(props: IReportStatsProps) {
  const sums = computeReportSums(props.transactionNodes)
  return (
    <>
      <ValueDisplay label='Month' boxProps={{ pr: 3, pb: 2 }}>
        <MonthPicker />
      </ValueDisplay>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <ValueDisplay
          label='Volume'
          value={String(sums.salesVolume)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Sales'
          value={currencyFormatter.format(sums.sales)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Gross'
          value={currencyFormatter.format(sums.gross)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Ext/Credit'
          value={currencyFormatter.format(sums.credit)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Tax'
          value={currencyFormatter.format(sums.tax)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Before Fees'
          value={currencyFormatter.format(sums.beforeFees)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Fees'
          value={currencyFormatter.format(sums.fees)}
          boxProps={{ px: 3, pb: 2 }}
        />
        <ValueDisplay
          label='Net'
          value={currencyFormatter.format(sums.net)}
          boxProps={{ px: 3, pb: 2 }}
        />
      </Box>
    </>
  )
}

export const ReportStats = memo(_ReportStats)
