import React from 'react'
import { Button, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    button: {
      marginLeft: spacing(3),
    },
  })
)

export const AdminLogout: React.FC = () => {
  const classes = useStyles()
  return (
    <Button component='a' href='/admin/logout' color='inherit' className={classes.button}>
      Logout
    </Button>
  )
}
