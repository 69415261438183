import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { IDeal } from '@lib/admin/types'
import { refetchQueries } from '@lib/admin/queries'
import { sendWelcomeEmail } from '@lib/admin/requests'

type ISendWelcomeEmailContentProps = {
  deal: IDeal
  onClose: () => void
}

export const SendWelcomeEmailContent: React.FC<ISendWelcomeEmailContentProps> = ({
  deal,
  onClose: close,
}: ISendWelcomeEmailContentProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // form values
  const [recipientName, setRecipientName] = useState<string>(deal.contactName)
  const [recipientEmail, setRecipientEmail] = useState<string>(deal.contactEmail)
  const [contactUpdate, setContactUpdate] = useState<boolean>(false)
  const [billingUpdate, setBillingUpdate] = useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const loadingSnack = enqueueSnackbar('Sending email...', {
      variant: 'info',
    })
    try {
      const res = await sendWelcomeEmail({
        dealId: deal.id,
        recipientName,
        recipientEmail,
        contactUpdate,
        billingUpdate,
      })
      closeSnackbar(loadingSnack)
      if (res.error) {
        enqueueSnackbar(res.error, { variant: 'error' })
      } else {
        enqueueSnackbar('Email sent!', { variant: 'success' })
        close()
        refetchQueries()
      }
    } catch (error) {
      closeSnackbar(loadingSnack)
      enqueueSnackbar('Network error while sending email', {
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display='flex' flexDirection='column' p={3}>
        <Typography variant='h6'>Send Welcome Email</Typography>
        {contactUpdate && (
          <FormControl margin='normal'>
            <TextField
              label='Full Name'
              value={recipientName}
              spellCheck={false}
              onChange={(e) => {
                setRecipientName(e.target.value)
              }}
            />
          </FormControl>
        )}
        <FormControl margin='normal'>
          <TextField
            label='Email'
            value={recipientEmail}
            autoFocus
            spellCheck={false}
            onChange={(e) => {
              setRecipientEmail(e.target.value)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={contactUpdate}
                onChange={(e) => {
                  setContactUpdate(e.target.checked)
                }}
              />
            }
            label='Save as primary contact'
          />
        </FormControl>
        <FormControl margin='normal'>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={billingUpdate}
                onChange={(e) => {
                  setBillingUpdate(e.target.checked)
                }}
              />
            }
            label='Save as billing contact'
          />
        </FormControl>
        <FormControl margin='normal'>
          <Button variant='contained' color='primary' type='submit'>
            Send
          </Button>
        </FormControl>
      </Box>
    </form>
  )
}
