import React from 'react'
import { Typography } from '@mui/material'

const TopText = ({ top }: { top: JSX.Element | string }) => {
  if (typeof top === 'string')
    return (
      <Typography variant='subtitle2' noWrap>
        {top || '\u00a0'}
      </Typography>
    )
  return (
    <Typography
      variant='subtitle2'
      component={top.type}
      {...top.props}
      className={top.props.className}
      noWrap
    >
      {top.props.children || '\u00a0'}
    </Typography>
  )
}

const BottomText = ({ bottom }: { bottom: JSX.Element | string }) => {
  if (typeof bottom === 'string')
    return (
      <Typography
        variant='caption'
        noWrap
        sx={({ palette }) => ({
          color: palette.text.disabled,
        })}
      >
        {bottom || '\u00a0'}
      </Typography>
    )
  return (
    <Typography
      variant='caption'
      component={bottom.type}
      className={bottom.props.className}
      sx={({ palette }) => ({
        '&:hover': {
          textDecoration: 'underline',
        },
        textDecoration: 'none',
        cursor: 'pointer',
        color: palette.text.disabled,
      })}
      noWrap
      {...bottom.props}
    >
      {bottom.props.children || '\u00a0'}
    </Typography>
  )
}

interface IContactProps {
  top: JSX.Element | string
  bottom: JSX.Element | string
}

export const DoubleText: React.FC<IContactProps> = ({ top, bottom }: IContactProps) => {
  return (
    <>
      <TopText top={top} />
      <BottomText bottom={bottom} />
    </>
  )
}
