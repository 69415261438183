import { PaletteMode } from '@mui/material'
import { atom, useAtom } from 'jotai'
import { parseCookies, setCookie } from 'nookies'

const paletteKey = 'palette'
const paletteAtom = atom<PaletteMode>((parseCookies()[paletteKey] as PaletteMode) ?? 'light')
const palettePersistence = atom(
  (get) => get(paletteAtom),
  (_get, set, newStr: PaletteMode) => {
    set(paletteAtom, newStr)
    setCookie(null, paletteKey, newStr)
  }
)

export const usePaletteAtom = (): [PaletteMode, (update: PaletteMode) => void | Promise<void>] =>
  useAtom(palettePersistence)
