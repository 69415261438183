import React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

type ValueDisplayProps = {
  label: string
  value?: string
  children?: JSX.Element
  boxProps?: BoxProps
}

export const ValueDisplay: React.FC<ValueDisplayProps> = ({ label, value, children, boxProps }) => {
  return (
    <Box {...boxProps}>
      <Typography variant='overline'>{label}</Typography>
      {children ? <Box>{children}</Box> : <Typography variant='h6'>{value}</Typography>}
    </Box>
  )
}
