import React, { memo } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ITransaction } from '@lib/admin/types'
import {
  capitalizeFirstLetter,
  computeTransactionTaxTotal,
  currencyFormatter,
  dateFormatter,
} from '@lib/admin/misc'

const useStyles = makeStyles(({ typography, palette }: Theme) =>
  createStyles({
    paymentTable: {
      width: 'auto',
      marginLeft: 'auto',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
    },
    link: {
      '&:hover': {
        textDecoration: 'underline',
      },
      textDecoration: 'none',
      color: palette.text.primary,
    },
  })
)

const PaymentRow = ({ transaction }: { transaction: ITransaction }) => {
  const classes = useStyles()
  const taxTotal = computeTransactionTaxTotal(transaction)
  return (
    <TableRow key={transaction.id}>
      <TableCell align='left'>{dateFormatter.format(new Date(transaction.date))}</TableCell>
      <TableCell align='left'>{currencyFormatter.format(transaction.gross)}</TableCell>
      <TableCell align='left'>{currencyFormatter.format(taxTotal)}</TableCell>
      <TableCell align='left'>{currencyFormatter.format(transaction.fee)}</TableCell>
      <TableCell align='left'>{capitalizeFirstLetter(transaction.type)}</TableCell>
      <TableCell align='left'>{capitalizeFirstLetter(transaction.processorName)}</TableCell>
      <TableCell align='right'>
        {transaction.url ? (
          <a className={classes.link} target='_blank' href={transaction.url} rel='noreferrer'>
            {transaction.processorId}
          </a>
        ) : (
          transaction.processorId
        )}
      </TableCell>
    </TableRow>
  )
}

const PaymentRowMemo = memo(PaymentRow)

interface IPaymentProps {
  transactions: ITransaction[]
  bullets?: boolean
}

export const Payments: React.FC<IPaymentProps> = ({ transactions }: IPaymentProps) => {
  const classes = useStyles()

  const paymentRows = transactions.map((transaction) => (
    <PaymentRowMemo key={transaction.id} transaction={transaction} />
  ))

  return transactions.length > 0 ? (
    <Table size='small' className={classes.paymentTable}>
      <TableHead>
        <TableRow>
          <TableCell align='left' className={classes.bold}>
            Date
          </TableCell>
          <TableCell align='left' className={classes.bold}>
            Gross
          </TableCell>
          <TableCell align='left' className={classes.bold}>
            Tax
          </TableCell>
          <TableCell align='left' className={classes.bold}>
            Fees
          </TableCell>
          <TableCell align='left' className={classes.bold}>
            Type
          </TableCell>
          <TableCell align='left' className={classes.bold}>
            Processor
          </TableCell>
          <TableCell align='right' className={classes.bold}>
            ID
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{paymentRows}</TableBody>
    </Table>
  ) : (
    <Typography align='center'>No Transactions</Typography>
  )
}
