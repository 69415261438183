import { ITransaction } from './types'

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  dateStyle: 'medium',
})

export const cleanUrl = (str: string): string => {
  return str.replace(/https?:\/\/|www\./gi, '').replace(/\//g, '')
}

export const capitalizeFirstLetter = (text: string): string => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
}

export function computeTransactionTaxTotal(transaction: ITransaction): number {
  return transaction.taxLines.reduce((accum, curr) => accum + curr.amount, 0)
}
