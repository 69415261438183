export const createUtcDate = (utcYear: number, utcMonth = 0, utcDate = 1): Date => {
  return new Date(Date.UTC(utcYear, utcMonth, utcDate))
}

export const getCurrentMonthRange = (): [Date, Date] => {
  const now = new Date()
  return [
    createUtcDate(now.getUTCFullYear(), now.getUTCMonth()),
    createUtcDate(now.getUTCFullYear(), now.getUTCMonth() + 1),
  ]
}

export const parseIsoDay = (param: string): Date | null => {
  const parts = param.split('-')

  if (parts.length === 3) {
    return new Date(
      Date.UTC(
        parseInt(parts[0]),
        parseInt(parts[1]) - 1, // convert from 1-based to 0-based
        1
      )
    )
  }

  return null
}

export const formatIsoDay = (date: Date): string => {
  // Use the CA locale to get the '-' character between units
  return date.toLocaleDateString('en-CA', { timeZone: 'UTC' })
}
