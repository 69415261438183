import React from 'react'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { useSearchQuery } from '@lib/admin/queries'
import { NewQuote } from '@components/admin/newQuote'
import { NewKey } from '@components/admin/newKey'
import { SearchBar } from '@components/admin/searchBar'
import { PaletteToggle } from '@components/admin/paletteToggle'
import { AdminLogout } from '@components/admin/adminLogout'

export const TopBar: React.FC = () => {
  const [, setSearchQuery] = useSearchQuery()
  return (
    <AppBar position='static' color='primary'>
      <Toolbar>
        <Typography
          variant='h6'
          component='a'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setSearchQuery('')
          }}
        >
          FullCalendar Admin
        </Typography>
        <Box ml={6} display='flex' alignItems='center'>
          <SearchBar />
        </Box>
        <Box ml={'auto'}>
          <NewQuote />
          <NewKey />
          <AdminLogout />
          <PaletteToggle />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
