import React from 'react'
import { Link } from 'gatsby'
import { Typography } from '@mui/material'
import InfinityIcon from '@mui/icons-material/AllInclusive'
import { IDeal } from '@lib/admin/types'
import { buildSearchParamStr } from '@lib/admin/queries'
import { DoubleText } from '@components/admin/doubleText'

export interface ILicenseProps {
  deal: IDeal
}

export const License: React.FC<ILicenseProps> = ({ deal }: ILicenseProps) => {
  return (
    <DoubleText
      top={
        <Typography style={{ display: 'flex' }}>
          <span style={{ flexGrow: 1 }}>{deal.licenseDescription}</span>
          {deal.autoRenew && (
            <InfinityIcon
              fontSize='small'
              color='disabled'
              style={{ verticalAlign: 'top', marginRight: '10px' }}
            />
          )}
        </Typography>
      }
      bottom={
        <Link to={buildSearchParamStr(deal.licenseKey)} title='View related licenses'>
          {deal.licenseKey}
        </Link>
      }
    />
  )
}
