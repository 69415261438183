import React from 'react'
import { PageProps } from 'gatsby'
import { Router } from '@reach/router'
import { useSearchQuery } from '@lib/admin/queries'
import { AppWrapper } from '@components/admin/appWrapper'
import { TopBar } from '@components/admin/topBar'
import { SearchScreen } from '@components/admin/searchScreen'
import { ReportScreen } from '@components/admin/reportScreen'

const AdminPage: React.FC<{ default: boolean }> = () => {
  const [searchQuery] = useSearchQuery()
  return (
    <AppWrapper>
      <TopBar />
      {searchQuery ? <SearchScreen /> : <ReportScreen />}
    </AppWrapper>
  )
}

const AdminPageRouterWrapper: React.FC<PageProps> = ({ location }) => {
  return (
    <Router location={location}>
      <AdminPage default />
    </Router>
  )
}

export default AdminPageRouterWrapper
