import { IDealNode } from '@lib/admin/types'

export const computeSearchSums = (dealNodeGroups: IDealNode[][]): number => {
  let totalPaid = 0
  for (const node of dealNodeGroups) {
    for (const deal of node) {
      if (deal.status === 'paid') {
        for (const transaction of deal.transactions) {
          totalPaid += transaction.gross
        }
      }
    }
  }
  return totalPaid
}
