import React, { memo } from 'react'
import { ValueDisplay } from '@components/admin/valueDisplay'
import { currencyFormatter } from '@lib/admin/misc'
import { IDealNode } from '@lib/admin/types'
import { computeSearchSums } from '@lib/admin/searchSums'

export interface ISearchStatsProps {
  usedQuery: string
  dealNodeGroups: IDealNode[][]
}

export const _SearchStats: React.FC<ISearchStatsProps> = ({
  usedQuery,
  dealNodeGroups,
}: ISearchStatsProps) => {
  return (
    <>
      <ValueDisplay label='Searching for' value={usedQuery} boxProps={{ pr: 3, pb: 2 }} />
      <ValueDisplay
        label='Results'
        value={dealNodeGroups.length.toString()}
        boxProps={{ px: 3, pb: 2 }}
      />
      <ValueDisplay
        label='Total Paid'
        value={currencyFormatter.format(computeSearchSums(dealNodeGroups))}
        boxProps={{ px: 3, pb: 2 }}
      />
    </>
  )
}

export const SearchStats = memo(_SearchStats)
