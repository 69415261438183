import React from 'react'
import { useSnackbar } from 'notistack'
import { IDeal } from '@lib/admin/types'
import { refetchQueries } from '@lib/admin/queries'
import { markDuplicateDeal } from '@lib/admin/requests'
import { ConfirmContent } from '@components/admin/confirm'

export interface IConfirmDuplicateContentProps {
  deal: IDeal
  onClose: () => void
}

export const ConfirmDuplicateContent: React.FC<IConfirmDuplicateContentProps> = ({
  deal,
  onClose,
}: IConfirmDuplicateContentProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleConfirm = () => {
    markDuplicateDeal(deal.id).then(
      (data) => {
        if (data.success) {
          enqueueSnackbar(`Deal (${deal.id}) marked as duplicate`, {
            variant: 'success',
          })
          refetchQueries()
        } else {
          enqueueSnackbar(data.error, { variant: 'error' })
        }
      },
      (error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    )
    onClose()
  }

  return (
    <ConfirmContent
      message={`Deal (${deal.id}) will be marked as duplicate`}
      onConfirm={handleConfirm}
      onCancel={onClose}
    />
  )
}
