import { ITransaction, ITransactionNode, IDeal } from '@lib/admin/types'
import { computeTransactionTaxTotal } from './misc'

export type ReportSumType = {
  salesVolume: number
  sales: number
  gross: number
  credit: number
  tax: number
  beforeFees: number
  fees: number
  net: number
}

export const computeReportSums = (transactionNodes: ITransactionNode[]): ReportSumType => {
  const salesHash: { [dealId: string]: IDeal } = {} // for preventing double-counting sales w/ mult transactions
  let sales = 0
  let salesVolume = 0
  let gross = 0
  let credit = 0
  let tax = 0
  let fees = 0

  for (const { deal, ...transaction } of transactionNodes) {
    if (transaction.type !== 'async') {
      gross += transaction.gross
      fees += transaction.fee
      tax += computeTransactionTaxTotal(transaction)
    }

    if (transaction.type === 'credit') {
      credit += transaction.gross
    }

    if (deal.status === 'paid' && isFirstPayment(transaction, deal)) {
      salesHash[deal.id] = deal
    }
  }

  for (const dealId in salesHash) {
    sales += salesHash[dealId].amount
    salesVolume++
  }

  const beforeFees = gross - credit - tax
  const net = beforeFees - fees

  return {
    salesVolume,
    sales,
    gross,
    credit,
    tax,
    beforeFees,
    fees,
    net,
  }
}

const FIRST_PAYMENT_THRESHOLD = 1000 * 60 * 60 * 24 * 10 // 10 days

const isFirstPayment = (transaction: ITransaction, deal: IDeal): boolean => {
  return (
    Math.abs(new Date(deal.paidDate).valueOf() - new Date(transaction.date).valueOf()) <
    FIRST_PAYMENT_THRESHOLD
  )
}
