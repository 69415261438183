import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Theme,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { IDeal } from '@lib/admin/types'
import { DiscountInput } from '@components/admin/discountInput'
import {
  clearQueryCaches,
  refetchQueries,
  useCoupons,
  usePaymentTerms,
  useSearchQuery,
} from '@lib/admin/queries'
import { createRenewalQuote } from '@lib/admin/requests'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(3),
    },
  })
)

export interface IRenewalQuoteContentProps {
  oldDeal: IDeal
  onClose: () => void
}

export const RenewalQuoteContent: React.FC<IRenewalQuoteContentProps> = ({
  oldDeal,
  onClose: close,
}: IRenewalQuoteContentProps) => {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [searchQuery, setSearchQuery] = useSearchQuery()
  const { paymentTerms } = usePaymentTerms()
  const { coupons } = useCoupons()

  // form values
  const [contactName, setContactName] = useState<string>(oldDeal.contactName)
  const [contactEmail, setContactEmail] = useState<string>(oldDeal.contactEmail)
  const [paymentTermId, setPaymentTermId] = useState<string>(
    paymentTerms.length ? paymentTerms[0].id : ''
  )
  const [couponId, setCouponId] = useState<string>('')
  const [disablePenalty, setDisablePenalty] = useState<boolean>(false)

  const saveQuote = async () => {
    const loadingSnack = enqueueSnackbar('Saving quote...', { variant: 'info' })
    try {
      const res = await createRenewalQuote({
        oldDealId: oldDeal.id,
        contactName,
        contactEmail,
        paymentTermId,
        couponId,
        disablePenalty,
      })
      closeSnackbar(loadingSnack)
      if (res.error) {
        enqueueSnackbar(res.error, { variant: 'error' })
      } else {
        enqueueSnackbar('Quote saved!', { variant: 'success' })
        close()
        if (searchQuery === contactEmail) {
          refetchQueries()
        } else {
          clearQueryCaches()
          setSearchQuery(contactEmail)
        }
        return res
      }
    } catch (error) {
      closeSnackbar(loadingSnack)
      enqueueSnackbar('Network error while saving quote', {
        variant: 'error',
      })
    }
  }
  const saveQuoteProceed = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const res = await saveQuote()
    if (res) {
      window.open(res.url)
    }
  }

  return (
    <form onSubmit={saveQuoteProceed}>
      <Box className={classes.contentBox}>
        <Typography variant='h6'>Renewal Quote</Typography>
        <FormControl margin='normal'>
          <TextField
            label='Full Name'
            autoFocus
            spellCheck={false}
            value={contactName}
            onChange={(e) => {
              setContactName(e.target.value)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <TextField
            label='Email'
            spellCheck={false}
            value={contactEmail}
            onChange={(e) => {
              setContactEmail(e.target.value)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <InputLabel id='payment-term-label'>Payment Term</InputLabel>
          <Select
            labelId='payment-term-label'
            value={paymentTermId}
            onChange={(e) => {
              setPaymentTermId(e.target.value as string)
            }}
          >
            {paymentTerms.map(({ id, description }) => (
              <MenuItem key={`payment-term-${id}`} value={id}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin='normal'>
          <DiscountInput
            coupons={coupons}
            value={couponId}
            onChange={(e) => {
              setCouponId(e.target.value as string)
            }}
          />
        </FormControl>
        <FormControl margin='normal'>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={disablePenalty}
                onChange={(e) => {
                  setDisablePenalty(e.target.checked)
                }}
              />
            }
            label='Preserve renewal discount'
          />
        </FormControl>
        <FormControl margin='normal'>
          <Button variant='contained' color='primary' type='submit'>
            Proceed
          </Button>
        </FormControl>
      </Box>
    </form>
  )
}
