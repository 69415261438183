import React from 'react'
import { IDeal } from '@lib/admin/types'
import { DoubleText } from '@components/admin/doubleText'
import { cleanUrl } from '@lib/admin/misc'

interface IContactProps {
  deal: IDeal
}

export const Contact: React.FC<IContactProps> = ({ deal }: IContactProps) => {
  return (
    <DoubleText
      top={deal.contactName}
      bottom={
        <a target='_blank' href={`mailto:${deal.contactEmail}`} rel='noreferrer'>
          {cleanUrl(deal.contactEmail)}
        </a>
      }
    />
  )
}
